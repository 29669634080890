<template>
  <v-container class="d-flex justify-center">
    <v-card min-width="500px">
      <v-card-title>Bejelentkezés</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="createNews">
          <v-container>
            <v-text-field
                v-model="title"
                label="Cím"
                required
            ></v-text-field>

            <v-text-field
                v-model="title_english"
                label="Angol cím"
                required
            ></v-text-field>

            <v-switch
                v-model="isHtml"
                label="Html?"
            ></v-switch>

            <v-textarea
                v-model="html"
                v-if="isHtml"
                label="Html szöveg"
                hint="Html szöveg"
            ></v-textarea>

            <v-textarea
                v-model="text"
                v-if="!isHtml"
                label="Szöveg"
                hint="Szöveg"
            ></v-textarea>

            <v-textarea
                v-model="html_english"
                v-if="isHtml"
                label="Html angol szöveg"
                hint="Html angol szöveg"
            ></v-textarea>

            <v-textarea
                v-model="text_english"
                v-if="!isHtml"
                label="Angol szöveg"
                hint="Angol szöveg"
            ></v-textarea>

            <v-btn class="mr-4" type="submit">
              Létrehozás
            </v-btn>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import db from './firebaseInit'

export default {
  name: "CreateNews",
  data() {
    return {
      title: '',
      title_english: '',
      isHtml: true,
      html: '',
      text: '',
      html_english: '',
      text_english: ''
    }
  },
  methods: {
    createNews() {
      db.collection('news').add({
        title: this.title,
        title_english: this.title_english,
        isHtml: this.isHtml,
        html: this.html,
        text: this.text,
        html_english: this.html_english,
        text_english: this.text_english,
        date: new Date()
      })
          .then(docRef => {
            console.log('News added: ', docRef.id)
            this.$router.push('/news')
          })
          .catch(error => {
            console.error('Error adding news: ', error)
          })
    }
  }
}
</script>

<style scoped>

</style>
