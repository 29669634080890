<template>
  <v-container>
    <h1 class="text-center my-5">{{ $t('kindergarten.title') }}</h1>
    <p class="text-justify">
      {{ $t('kindergarten.text') }}
    </p>
    <v-row class="d-flex justify-center justify-space-around">
      <v-col xs="12" sm="6" md="6" lg="6">
        <v-card class="myCard">
          <v-card-title>
            <div class="image-wrapper">
              <img src="../assets/images/buildings/21.jpg" alt="Épület - Simonyi út 21.">
            </div>
          </v-card-title>
          <v-card-text>
            <h2 class="mb-5">{{ $t('kindergarten.address_21') }}</h2>
            <p>
              <v-icon>mdi-phone</v-icon>
              {{ $t('kindergarten.phone') }}: <strong>0652/316-845</strong></p>
            <p>
              <v-icon>mdi-email</v-icon>
              Email: <strong>simonyi@ovoda.debrecen.hu</strong></p>
            <p>
              <v-icon>mdi-clock</v-icon>
              {{ $t('kindergarten.opening_hours') }}: <strong>{{ $t('kindergarten.opening_hours_text') }}</strong></p>

            <p>
              <v-icon>mdi-account-circle</v-icon>
              {{$t('kindergarten.responsible_authority')}}: <strong>{{$t('kindergarten.responsible_authority_text')}}</strong>
            </p>

            <v-container class="d-flex justify-center flex-column">
              <div class="d-flex justify-center">
                <v-btn plain to="/groups" top>{{$t('kindergarten.groups')}}</v-btn>
              </div>

              <v-row class="d-flex justify-center mt-10 mb-5">
                <v-col xs="12" sm="6" md="6" lg="3" v-for="(group, i) in group1" :key="i">
                  <v-btn plain :to="{name: 'group', params: {name: group.name}}" block height="auto">
                    <v-avatar :size="120" style="background-color: orange">
                      <div class="image-wrapper">
                        <img :src=group.src :alt="group.name + ' - Simonyi úti óvoda'">
                      </div>
                    </v-avatar>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="6" md="6" lg="6">
        <v-card class="myCard">
          <v-card-title>
            <div class="image-wrapper">
              <img src="../assets/images/buildings/31.jpg" alt="Épület - Simonyi út 31-33">
            </div>
          </v-card-title>
          <v-card-text>
            <h2 class="mb-5">{{ $t('kindergarten.address_31_33') }}</h2>
            <p>
              <v-icon>mdi-phone</v-icon>
              {{ $t('kindergarten.phone') }}: <strong>0652/314-008</strong></p>
            <p>
              <v-icon>mdi-email</v-icon>
              Email: <strong>simonyi@ovoda.debrecen.hu</strong></p>
            <p>
              <v-icon>mdi-clock</v-icon>
              {{ $t('kindergarten.opening_hours') }}: <strong>{{ $t('kindergarten.opening_hours_text') }}</strong></p>

            <p>
              <v-icon>mdi-account-circle</v-icon>
              {{$t('kindergarten.responsible_authority')}}: <strong>{{$t('kindergarten.responsible_authority_text')}}</strong>
            </p>

            <v-container class="d-flex justify-center flex-column">
              <div class="d-flex justify-center">
                <v-btn plain to="/groups" top>{{$t('kindergarten.groups')}}</v-btn>
              </div>

              <v-row class="d-flex justify-center mt-10 mb-5">
                <v-col xs="12" sm="6" md="6" lg="3" v-for="(group, i) in group2" :key="i">
                  <v-btn plain :to="{name: 'group', params: {name: group.name}}" block height="auto">
                    <v-avatar :size="120" style="background-color: orange">
                      <div class="image-wrapper">
                        <img :src=group.src :alt="group.name + ' - Simonyi úti óvoda'">
                      </div>
                    </v-avatar>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <app-google-maps></app-google-maps>
  </v-container>
</template>

<script>
import MyGoogleMaps from "@/components/MyGoogleMaps.vue";

export default {
  title() {
    return `${this.$t('header.kindergarten')} - ${this.$t('header.name')}`
  },
  name: "Buildings",
  data() {
    return {
      group1: [
        {
          src: require('../assets/images/groups/tulipan.png'),
          name: 'tulipan'
        },
        {
          src: require('../assets/images/groups/napocska.png'),
          name: 'napocska'
        },
        {
          src: require('../assets/images/groups/margareta.png'),
          name: 'margareta'
        },
      ],
      group2: [
        {
          src: require('../assets/images/groups/suni.png'),
          name: 'suni'
        },
        {
          src: require('../assets/images/groups/halacska.png'),
          name: 'halacska'
        },
        {
          src: require('../assets/images/groups/pillango.png'),
          name: 'pillango'
        },
        {
          src: require('../assets/images/groups/katica.png'),
          name: 'katica'
        },
      ],
    }
  },
  components: {
    appGoogleMaps: MyGoogleMaps
  }
}
</script>

<style scoped lang="scss">
.myCard {
  min-width: 280px;
  height: 100%;
}

.image-wrapper {
  & img {
    width: 100%;
    height: 100%;
  }
}
</style>