<template>
  <v-container class="mt-5" :style="{backgroundColor: slider[id].color}">
    <div class="slider slider__text">{{ $t(slider[id].text) }}</div>
    <div class="d-flex align-center">
      <div class="slider slider__author">{{ $t(slider[id].author) }}</div>
    </div>
  </v-container>
</template>

<script>
import {parentMixin} from "@/components/mixins/parentMixin";

export default {
  name: "ParentSaidItem",
  mixins: [parentMixin],
  data() {
    return {
      id: this.$route.params.id,
    }
  }
}
</script>

<style scoped lang="scss">
.slider {
  color: white;
  font-family: "Comic Sans MS",cursive;
  font-weight: bold;

  &__text {
    font-size: 18px;
  }

  &__author {
    font-size: 16px;
    margin-left: auto;
  }
}
</style>