<template>
  <div class="card">
    <div class="card__side card__side--front">
      <div class="card__picture" :class="'card__picture--'+card.name">
        &nbsp;
      </div>
      <h4 class="card__heading">
        <span class="card__heading-span" :class="'card__heading-span--'+card.name">{{ $t(card.title) }}</span>
      </h4>
      <div class="card__details">
        <ul>
          <li v-for="(employer, index) in card.employers" :key="index">
            {{ $t('groups.employer', {firstname: employer.firstname, lastname: employer.lastname}) }} ({{$t(employer.rank)}})</li>
        </ul>
      </div>
    </div>
    <div class="card__side card__side--back" :class="'card__side--back-'+card.name">
      <div class="card__back-box">
        <p class="card__back-box-text" v-for="(text, index) in card.card_back"
           :key="index">{{ $t(text) }}</p>
        <v-btn :to="{name: 'group', params: {name: card.name}}"
               class="btn mb-10">{{$t('groups.more')}}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlipCard",
  props: ['card'],
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
@import "src/assets/scss/main";

.card {
  //Functionality
  perspective: 150rem; // Élethűbb forgatás; Egy nagy szám kell, máskülönben perspektív torzítás miatt fura eredmény lesz
  -moz-perspective: 150rem;
  position: relative;
  height: 520px;
  min-width: 275px;

  &__side {
    height: 520px;
    transition: all 0.8s ease; // ease paraméter simábbá teszi az animációt
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 3px;
    overflow: hidden; // a kép a border-radius-t elfedné
    box-shadow: 0 15px 40px rgba($color-black, 0.15);

    &--front {
      background-color: $color-white;
    }

    &--back {
      transform: rotateY(180deg);

      &-tulipan {
        background-image: linear-gradient(to right bottom, $color-tulipan-light, $color-tulipan-dark);
      }

      &-napocska {
        background-image: linear-gradient(to right bottom, $color-napocska-light, $color-napocska-dark);
      }

      &-margareta {
        background-image: linear-gradient(to right bottom, $color-margareta-light, $color-margareta-dark);
      }

      &-suni {
        background-image: linear-gradient(to right bottom, $color-suni-light, $color-suni-dark);
      }

      &-halacska {
        background-image: linear-gradient(to right bottom, $color-halacska-light, $color-halacska-dark);
      }

      &-pillango {
        background-image: linear-gradient(to right bottom, $color-pillango-light, $color-pillango-dark);
      }

      &-katica {
        background-image: linear-gradient(to right bottom, $color-katica-light, $color-katica-dark);
      }
    }
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    transform: rotateY(0);
  }

  //Front side styling
  &__picture {
    background-size: cover;
    height: 230px;
    background-blend-mode: screen; // a linear-gradient és a kép összemosásának a módja (érdekes :)
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-position-y: center;

    &--tulipan {
      background-image: linear-gradient(to right bottom, $color-tulipan-light, $color-tulipan-dark),
      url(../assets/images/groups/tulipan.png);
      background-position-y: top;
    }

    &--napocska {
      background-image: linear-gradient(to right bottom, $color-napocska-light, $color-napocska-dark),
      url(../assets/images/groups/napocska.png);
    }

    &--margareta {
      background-image: linear-gradient(to right bottom, $color-margareta-light, $color-margareta-dark),
      url(../assets/images/groups/margareta.png);
    }

    &--suni {
      background-image: linear-gradient(to right bottom, $color-suni-light, $color-suni-dark),
      url(../assets/images/groups/suni.png);
    }

    &--halacska {
      background-image: linear-gradient(to right bottom, $color-halacska-light, $color-halacska-dark),
      url(../assets/images/groups/halacska.png);
    }

    &--pillango {
      background-image: linear-gradient(to right bottom, $color-pillango-light, $color-pillango-dark),
      url(../assets/images/groups/pillango.png);
    }

    &--katica {
      background-image: linear-gradient(to right bottom, $color-katica-light, $color-katica-dark),
      url(../assets/images/groups/katica.png);
    }
  }

  &__heading {
    font-size: 28px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    color: $color-white;
    position: absolute;
    top: 120px;
    right: 20px;
    width: 75%;
  }

  &__heading-span {
    // nem teljesen a heading része, ezért raktuk spam-be és ezért nem a __heading-be ágyazzuk be

    padding: 10px 15px;
    box-decoration-break: clone; // Nélküle a sortörésnél nem egységesek a padding-ek

    &--tulipan {
      background-image: linear-gradient(
              to right bottom,
              rgba($color-tulipan-light, 0.85),
              rgba($color-tulipan-dark, 0.85)
      );
    }

    &--napocska {
      background-image: linear-gradient(
              to right bottom,
              rgba($color-napocska-light, 0.85),
              rgba($color-napocska-dark, 0.85)
      );
    }

    &--margareta {
      background-image: linear-gradient(
              to right bottom,
              rgba($color-margareta-light, 0.85),
              rgba($color-margareta-dark, 0.85)
      );
    }

    &--suni {
      background-image: linear-gradient(
              to right bottom,
              rgba($color-suni-light, 0.85),
              rgba($color-suni-dark, 0.85)
      );
    }

    &--halacska {
      background-image: linear-gradient(
              to right bottom,
              rgba($color-halacska-light, 0.85),
              rgba($color-halacska-dark, 0.85)
      );
    }

    &--pillango {
      background-image: linear-gradient(
              to right bottom,
              rgba($color-pillango-light, 0.85),
              rgba($color-pillango-dark, 0.85)
      );
    }

    &--katica {
      background-image: linear-gradient(
              to right bottom,
              rgba($color-katica-light, 0.85),
              rgba($color-katica-dark, 0.85)
      );
    }
  }

  &__details {
    padding: 30px;

    ul {
      list-style: none;
      width: 100%;
      margin: 0 auto;
      padding: 0;

      li {
        text-align: center;
        font-size: 15px;
        padding: 10px;

        &:not(:last-child) {
          border-bottom: 1px solid $color-grey-light-2;
        }
      }
    }
  }

  //Back side styling
  &__back-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    color: white;
  }

  &__back-box-text {
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
  }

  &__introduction-paragraph {
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
  }


  @media #{map-get($display-breakpoints, 'md-and-down')} {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);

    &__side {
      height: auto;
      position: relative;
      box-shadow: none;

      &--front {
        flex: 2;
      }

      &--back {
        transform: rotateY(0);
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
        flex: 1;
      }
    }

    &:hover &__side--front {
      transform: rotateY(0);
    }

    &__details {
      padding: 10px 30px;
    }

    //Back side styling
    &__back-box {
      padding-top: 50px;
    }
  }
}
</style>