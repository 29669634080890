import Home from "@/components/Home";
import Gallery from "@/components/Groups";
import Buildings from "@/components/Buildings";
import Group from "@/components/Group";
import AboutUs from "@/components/AboutUs";
import ParentSaid from "@/components/ParentSaidItem";
import Documents from "@/components/Documents";
import News from "@/components/News";
import SignIn from "@/components/SignIn";
// import SignUp from "@/components/SignUp";
import CreateNews from "@/components/CreateNews";

export const routes = [
    {
        path: '/', component: Home
    },
    {
        path: '/buildings', component: Buildings
    },
    {
        path: '/groups', component: Gallery, name: 'groups'
    },
    {
        path: '/group/:name', component: Group, name: 'group'
    },
    {
        path: '/aboutus', component: AboutUs, name: 'aboutus'
    },
    {
        path: '/parentsaid/:id', component: ParentSaid, name: 'parentSaid'
    },
    {
        path: '/documents', component: Documents, name: 'documents'
    },
    {
        path: '/news', component: News, name: 'news'
    },

    {
        path: '/signin', component: SignIn, name: 'signin'
    },
    // {
    //     path: '/signup', component: SignUp, name: 'signup'
    // },
    {
        path: '/createnews', component: CreateNews, name: 'createnews',
        meta: {
            requiresAuth: true
        }
    },
];