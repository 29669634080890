import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        currentUser: false,
        language: 'hu'
    },
    getters:{
        isLoggedIn: state => {
            return state.isLoggedIn;
        },
        currentUser: state => {
            return state.currentUser;
        },
        language: state => {
          return state.language;
        }
    },
    mutations: {
        initialaiseStore: (state => {
           if (localStorage.getItem('language')) {
               state.language = localStorage.getItem('language')
           }
        }),
        updateIsLoggedIn: (state, payload) => {
            state.isLoggedIn = payload;
        },
        updateCurrentUser: (state, payload) => {
            state.currentUser = payload;
        },
        updateLanguage: (state, payload) => {
            state.language = payload;
        }
    }
});