<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h3>{{ $t('parentsSay.title') }}</h3>
      </v-card-title>
      <v-card-text>
        <v-carousel height="300">
          <template v-for="(item, index) in slider">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
              <v-row class="flex-nowrap" style="height:100%">
                <template v-for="(n,i) in columns">
                  <template v-if="(+index + i) < slider.length">
                    <v-col :key="i">
                      <v-sheet v-if="(+index + i) < slider.length"
                               :color="slider[+index + i].color"
                               height="100%"
                               class="px-10"
                      >
                        <v-row class="fill-height d-flex flex-column justify-space-around"
                        >
                          <div class="slider slider__text">{{ add3Dots($t(slider[+index + i].text)) }}</div>
                          <div class="d-flex align-center">
                            <div v-if="isAdd3Dots($t(slider[+index + i].text))">
                              <v-btn v-bind="size" outlined :to="{name: 'parentSaid', params: {id: +index + i}}">
                                {{ $t('parentsSay.more') }}
                              </v-btn>
                            </div>
                            <div class="slider slider__author">{{ $t(slider[+index + i].author) }}</div>
                          </div>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {parentMixin} from "@/components/mixins/parentMixin";

export default {
  name: "ParentSaid",
  mixins: [parentMixin],
  methods: {
    add3Dots(string, limit = 250) {
      let dots = "...";
      if (string.length > limit) {
        string = string.substring(0, limit) + dots;
      }

      return string;
    },
    isAdd3Dots(string, limit = 250) {
      return string.length > limit;
    }
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 2;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 2;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
    size() {
      const size = {xs: 'x-small', sm: 'small', lg: 'large', xl: 'x-large'}[this.$vuetify.breakpoint.name];
      return size ? {[size]: true} : {}
    }
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.slider {
  font-family: "Comic Sans MS", cursive;
  font-weight: bold;

  &__text {
    font-size: 18px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 13px;
    }
  }

  &__author {
    font-size: 16px;
    margin-left: auto;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 13px;
    }
  }
}

.quotation {
  font-family: "Comic Sans MS", cursive;
  font-weight: bold;

  &__text {
    word-break: normal;
  }

  &__author {
    align-self: flex-end;
  }
}
</style>