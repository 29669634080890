export const groupsMixin = {
    data() {
        return {
            group1: ['tulipan', 'napocska', 'margareta'],
            group2: ['suni', 'halacska', 'pillango', 'katica'],
            tulipan: {
                title: 'groups.tulip.title',
                employers: [
                    {firstname: 'Veronika', lastname: 'Bereczki', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Ildikó', lastname: 'Király', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Elek Katalin', lastname: 'Csukáné', rank: 'groups.carer'},
                ],
                card_back: ['groups.age_group_5_6', 'groups.type_same'],
                introduction_sections: [
                    'groups.tulip.section_1',
                    'groups.tulip.section_2',
                    'groups.tulip.section_3',
                    'groups.tulip.section_4',
                ],
                name: 'tulipan',
                gallerySrc: [
                    "tulipan/1.jpg",
                    "tulipan/2.jpg",
                    "tulipan/3.jpg",
                    "tulipan/4.jpg",
                    "tulipan/5.jpg",
                    "tulipan/6.jpg",
                    "tulipan/7.jpg",
                    "tulipan/8.jpg",
                    "tulipan/9.jpg",
                    "tulipan/10.jpg",
                    "tulipan/11.jpg",
                    "tulipan/12.jpg",
                    "tulipan/13.jpg",
                    "tulipan/14.jpg",
                    "tulipan/15.jpg",
                    "tulipan/16.jpg",
                    "tulipan/17.jpg",
                    "tulipan/18.jpg",
                    "tulipan/19.jpg",
                    "tulipan/20.jpg",
                    "tulipan/21.jpg",
                    "tulipan/22.jpg",
                    "tulipan/23.jpg",
                ]
            },
            napocska: {
                title: 'groups.sun.title',
                employers: [
                    {firstname: 'Edina', lastname: 'Gavallérné Kiss', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Panna', lastname: 'Huszti', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Gitta', lastname: 'Szabó-Molnár', rank: 'groups.teaching_assistant'},
                    {firstname: 'Erika', lastname: 'Mesterné Orsó', rank: 'groups.carer'},
                ],
                card_back: ['groups.age_group_3_4', 'groups.type_same'],
                introduction_sections: [
                    'groups.sun.section_1',
                    'groups.sun.section_2',
                    'groups.sun.section_3',
                    'groups.sun.section_4',
                    'groups.sun.section_5'
                ],
                name: 'napocska',
                gallerySrc: [
                    "napocska/1.jpg",
                    "napocska/2.jpg",
                    "napocska/3.jpg",
                    "napocska/4.jpg",
                    "napocska/5.jpg",
                    "napocska/6.jpg",
                    "napocska/7.jpg",
                    "napocska/8.jpg",
                    "napocska/9.jpg",
                    "napocska/10.jpg",
                    "napocska/11.jpg",
                    "napocska/12.jpg",
                    "napocska/13.jpg",
                    "napocska/14.jpg",
                    "napocska/15.jpg",
                    "napocska/16.jpg",
                    "napocska/17.jpg"
                ]
            },
            margareta: {
                title: 'groups.daisy.title',
                employers: [
                    {firstname: 'Mónika', lastname: 'Burai Pál Józsefné ', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Sarolta', lastname: 'Papp', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Katalin', lastname: 'Vargáné Bakos', rank: 'groups.carer'},
                ],
                card_back: ['groups.age_group_4_5', 'groups.type_same'],
                introduction_sections: [
                    'groups.daisy.section'
                ],
                name: 'margareta',
                gallerySrc: [
                    "margareta/1.jpg",
                    "margareta/2.jpg",
                    "margareta/3.jpg",
                    "margareta/4.jpg",
                    "margareta/5.jpg",
                    "margareta/6.jpg",
                    "margareta/7.jpg",
                    "margareta/8.jpg",
                    "margareta/9.jpg",
                    "margareta/10.jpg",
                    "margareta/11.jpg",
                    "margareta/12.jpg",
                    "margareta/13.jpg",
                    "margareta/14.jpg",
                    "margareta/15.jpg",
                    "margareta/16.jpg",
                ]
            },
            suni: {
                title: 'groups.hedgehog.title',
                employers: [
                    {firstname: 'Tünde', lastname: 'Hegedüsné Laza', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Judit', lastname: 'Orgován', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Magdolna', lastname: 'Kuna', rank: 'groups.carer'},
                ],
                card_back: ['groups.age_group_5_6', 'groups.type_same'],
                introduction_sections: [
                    'groups.hedgehog.section_1'
                ],
                name: 'suni',
                gallerySrc: [
                    "suni/1.jpg",
                    "suni/2.jpg",
                    "suni/3.jpg",
                    "suni/4.jpg",
                    "suni/5.jpg"
                ]
            },
            halacska: {
                title: 'groups.fish.title',
                employers: [
                    {firstname: 'Gabriella', lastname: 'Rádokiné Madarász', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Viktória', lastname: 'Szilágyi', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Erzsike', lastname: 'Czinege Zsoltné ', rank: 'groups.carer'},
                ],
                card_back: ['groups.age_group_6_7', 'groups.type_same'],
                introduction_sections: [
                    'groups.fish.section_1',
                    'groups.fish.section_2',
                    'groups.fish.section_3'
                ],
                name: 'halacska',
                gallerySrc: [
                    "halacska/1.jpg",
                    "halacska/2.jpg",
                    "halacska/3.jpg",
                    "halacska/4.jpg",
                    "halacska/5.jpg",
                    "halacska/6.jpg",
                    "halacska/7.jpg",
                    "halacska/8.jpg",
                    "halacska/9.jpg",
                    "halacska/10.jpg",
                    "halacska/11.jpg",
                    "halacska/12.jpg",
                    "halacska/13.jpg",
                    "halacska/14.jpg",
                    "halacska/15.jpg",
                    "halacska/16.jpg",
                    "halacska/17.jpg",
                    "halacska/18.jpg",
                    "halacska/19.jpg",
                    "halacska/20.jpg",
                    "halacska/21.jpg",
                    "halacska/22.jpg",
                    "halacska/23.jpg",
                    "halacska/24.jpg",
                    "halacska/25.jpg",
                    "halacska/26.jpg"
                ]
            },
            pillango: {
                title: 'groups.butterfly.title',
                card_back: ['groups.age_group_3_4', 'groups.type_same'],
                employers: [
                    {firstname: 'Valéria ', lastname: 'Molnár', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Mónika', lastname: 'Szőkéné Prétli', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Ági', lastname: 'Király Csabáné', rank: 'groups.carer'}
                ],
                introduction_sections: [
                    'groups.butterfly.section_1',
                    'groups.butterfly.section_2',
                    'groups.butterfly.section_3',
                    'groups.butterfly.section_4'
                ],
                name: 'pillango',
                gallerySrc: [
                    "pillango/1.jpg",
                    "pillango/2.jpg",
                    "pillango/3.jpg",
                    "pillango/4.jpg",
                    "pillango/5.jpg",
                    "pillango/6.jpg"
                ]
            },
            katica: {
                title: 'groups.ladybird.title',
                card_back: ['groups.age_group_4_5', 'groups.type_same'],
                employers: [
                    {firstname: 'Ildikó', lastname: 'Nagyné Török', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Krisztina', lastname: 'Bakonyi', rank: 'groups.kindergarten_teacher'},
                    {firstname: 'Katika', lastname: 'Radócz Sándorné', rank: 'groups.teaching_assistant'},
                    {firstname: 'Anikó', lastname: 'Kaprinyák', rank: 'groups.carer'}
                ],
                introduction_sections: [
                    'groups.ladybird.section'
                ],
                name: 'katica',
                gallerySrc: [
                    "katica/1.jpg",
                    "katica/2.jpg",
                    "katica/3.jpg",
                    "katica/4.jpg",
                    "katica/5.jpg"
                ]
            },
        }
    },
    methods: {
        getObject(name) {
            return this[name]
        }
    }
}
