<template>
    <v-card min-width="250px" style="height: 100%">
      <v-card-title class="d-flex flex-column justify-center">
        <v-avatar :size="120" style="background-color: orange">
          <div class="image-wrapper">
            <img :src="getImgUrl(task.image)" alt="Icon - Simonyi úti óvoda">
          </div>
        </v-avatar>
        <div>{{ $t(task.title) }}</div>
      </v-card-title>
      <v-card-text>
        <p style="text-align: center;">{{ $t(task.text) }}</p>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: "Task",
  props: ['task'],
  methods: {
    getImgUrl(pic) {
      return require('../assets/icons/'+pic)
    }
  }
}
</script>

<style scoped>
 .image-wrapper {
   width: 85px;
   height: 85px;
 }
</style>