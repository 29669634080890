<template>
  <v-container>
    <v-card elevation="2" outlined>
      <v-card-title class="d-flex justify-center mt-5">
        <h1 class="card__title">{{ $t(getObject(name).title) }} {{ $t('groups.group') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-container>
          <p v-for="(introduction, index) in getObject(name).introduction_sections" :key="index">{{
              $t(introduction)
            }}</p>
        </v-container>
        <v-container style="background-color: orange">
          <v-row>
            <v-col lg="12">
              <app-gallery :folder=name></app-gallery>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {groupsMixin} from "@/components/mixins/groupsMixin";
import Gallery from "@/components/Gallery.vue";

export default {
  title() {
    return `${this.$t(this.getObject(this.$route.params.name).title)} - ${this.$t('header.name')}`
  },
  name: "Group",
  mixins: [groupsMixin],
  data() {
    return {
      name: this.$route.params.name,
    }
  },
  components: {
    appGallery: Gallery
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.card__title {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 1.6em;
  }
}

p {
  text-align: justify;
  padding-right: 10px;
  padding-left: 10px;
}
</style>