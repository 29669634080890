<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title class="flex-column">
          <h1>{{ $t('groups.aboutUs.title') }}</h1>
          <p class="quotation quotation__text mt-5">{{ $t('groups.aboutUs.quote') }}</p>
          <p class="quotation quotation__author">{{ $t('groups.aboutUs.quote_author') }}</p>
        </v-card-title>
        <v-card-text>
          <p class="card__text" v-html="$t('groups.aboutUs.section_1')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_2')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_3')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_4')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_5')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_6')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_7')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_8')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_9')"></p>
          <p class="card__text" v-html="$t('groups.aboutUs.section_10')"></p>
          <p class="important-paragraph" v-html="$t('groups.aboutUs.section_11')"></p>
        </v-card-text>
      </v-card>

      <v-card class="mt-5">
        <v-card-title class="d-flex justify-center py-8">
          <h1>{{ $t('groups.aboutUs.ourStaff') }}</h1>
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-center flex-column">
            <p><span class="colleague__position">{{ $t(colleague_list[0].position) }}:</span>
              {{
                $t('groups.employer', {
                  lastname: colleague_list[0].colleagues[0].lastname,
                  firstname: colleague_list[0].colleagues[0].firstname
                })
              }}
            </p>
            <p><span class="colleague__position">{{ $t(colleague_list[1].position) }}:</span>
              {{
                $t('groups.employer', {
                  lastname: colleague_list[1].colleagues[0].lastname,
                  firstname: colleague_list[1].colleagues[0].firstname
                })
              }}
            </p>
            <p><span class="colleague__position">{{ $t(colleague_list[2].position) }}:</span>
              {{
                $t('groups.employer', {
                  lastname: colleague_list[2].colleagues[0].lastname,
                  firstname: colleague_list[2].colleagues[0].firstname
                })
              }}
            </p>
            <v-row class="d-flex justify-space-around" style="width: 100%">
              <v-col xs="12" class="justify-center">
                <p class="colleague__position">{{ $t(colleague_list[3].position) }}</p>
                <p class="colleague__name" v-for="(name, i) in colleague_list[3].colleagues" :key="('A' + i)">
                  {{
                    $t('groups.employer', {
                      lastname: name.lastname,
                      firstname: name.firstname
                    })
                  }}
                </p>
              </v-col>
              <v-col xs=12>
                <p class="colleague__position">{{ $t(colleague_list[5].position) }}</p>
                <p class="colleague__name" v-for="(name, i) in colleague_list[5].colleagues" :key="('B' + i)">
                  {{
                    $t('groups.employer', {
                      lastname: name.lastname,
                      firstname: name.firstname
                    })
                  }}
                </p>

                <p class="colleague__position">{{ $t(colleague_list[6].position) }}</p>
                <p class="colleague__name" v-for="(name, i) in colleague_list[6].colleagues" :key="('C' + i)">
                  {{
                    $t('groups.employer', {
                      lastname: name.lastname,
                      firstname: name.firstname
                    })
                  }}
                </p>

                <p class="colleague__position">{{ $t(colleague_list[7].position) }}</p>
                <p class="colleague__name" v-for="(name, i) in colleague_list[7].colleagues" :key="('D' + i)">
                  {{
                    $t('groups.employer', {
                      lastname: name.lastname,
                      firstname: name.firstname
                    })
                  }}
                </p>
              </v-col>
            </v-row>

            <p class="colleague__position">{{ $t(colleague_list[4].position) }}</p>
            <p v-for="(name, i) in colleague_list[4].colleagues" :key="('E' + i)">
              {{
                $t('groups.employer', {
                  lastname: name.lastname,
                  firstname: name.firstname
                })
              }}
            </p>
          </div>
        </v-card-text>
      </v-card>
      <optional-tasks></optional-tasks>
    </v-container>
  </div>
</template>

<script>
import OptionalTasks from "@/components/OptionalTasks.vue";

export default {
  title() {
    return `${this.$t('header.aboutus')} - ${this.$t('header.name')}`
  },
  name: "AboutUs",
  components: {
    OptionalTasks
  },
  data() {
    return {
      colleague_list: [
        {position: 'groups.aboutUs.director', colleagues: [{firstname: 'Ferencné', lastname: 'Szabó'}]},
        {position: 'groups.aboutUs.director_assistant', colleagues: [{firstname: 'Józsefné', lastname: 'Burai Pál'}]},
        {position: 'groups.aboutUs.secretary', colleagues: [{firstname: 'Katalin', lastname: 'Prekopcsákné Tokaji'}]},
        {
          position: 'groups.aboutUs.kindergarten_teachers', colleagues: [
            {firstname: 'Veronika', lastname: 'Bereczki'},
            {firstname: 'Józsefné', lastname: 'Burai Pál'},
            {firstname: 'Edina', lastname: 'Gavallérné Kiss'},
            {firstname: 'Tünde', lastname: 'Hegedűsné Laza'},
            {firstname: 'Ildikó', lastname: 'Király'},
            {firstname: 'Valéria', lastname: 'Molnár'},
            {firstname: 'Sarolta', lastname: 'Papp'},
            {firstname: 'Ildikó', lastname: 'Nagyné Török'},
            {firstname: 'Judit', lastname: 'Orgován'},
            {firstname: 'Gabriella', lastname: 'Rádokiné Madarász'},
            {firstname: 'Viktória', lastname: 'Németh-Szilágyi'},
            {firstname: 'Mónika', lastname: 'Szőkéné Prétli'},
            {firstname: 'Panna', lastname: 'Huszti'},
            {firstname: 'Krisztina', lastname: 'Bakonyi'}
          ]
        },
        {
          position: 'groups.aboutUs.teaching_assistants', colleagues: [
            {firstname: 'Gitta', lastname: 'Szabó-Molnár'},
            {firstname: 'Sándorné', lastname: 'Radócz'},
          ]
        },
        {
          position: 'groups.aboutUs.carers', colleagues: [
            {firstname: 'Zsoltné', lastname: 'Czinege'},
            {firstname: 'Katalin', lastname: 'Csukáné Elek'},
            {firstname: 'Anikó', lastname: 'Kaprinyák'},
            {firstname: 'Csabáné', lastname: 'Király'},
            {firstname: 'Magdolna', lastname: 'Kuna'},
            {firstname: 'Erika', lastname: 'Mesterné Orsó'},
            {firstname: 'Katalin', lastname: 'Vargáné Bakos'}
          ]
        },
        {
          position: 'groups.aboutUs.kitchen_staff', colleagues: [
            {firstname: 'Judit', lastname: 'Erdeiné Péterszegi'},
            {firstname: 'Tímea', lastname: 'Nagy'},
            {firstname: 'Ildikó', lastname: 'Göcs-Vágó'}
          ]
        },
        {
          position: 'groups.aboutUs.caretakers', colleagues: [
            {firstname: 'Sándor', lastname: 'Katona'},
            {firstname: 'István', lastname: 'Lipcsei'}
          ]
        },
      ]
    }
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.card {
  &__text {
    text-align: justify;
  }
}

.colleague {
  &__position {
    font-weight: bold;
    text-align: center;
  }

  &__name {
    text-align: center;
  }

  &__wrapper {
    column-count: 3;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      column-count: 2;
    }
  }
}
</style>
<style lang="scss">
  .italic {
    font-style: italic;
  }

  .important-paragraph {
    font-weight: bold;
    color: black;
    font-size: large;
  }
</style>
