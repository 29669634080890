<template>
  <v-container>
    <template v-for="(n, index) in sortNews">
      <v-card :key="'A' + index">
        <v-card-title class="justify-center">
          <template v-if="isEn && n.title_english">
            {{ n.title_english }}
          </template>
          <template v-else>
            {{ n.title }}
          </template>
        </v-card-title>
        <template v-if="n.isHtml && isEn && n.html_english">
          <v-card-text v-html="n.html_english"></v-card-text>
        </template>
        <template v-else>
          <v-card-text v-html="n.html"></v-card-text>
        </template>

        <v-card-text v-if="!n.isHtml">
          <p class="pl-3">
            <template v-if="isEn && n.text_english">
              {{ n.text_english }}
            </template>
            <template v-else>
              {{ n.text }}
            </template>
          </p>
        </v-card-text>
      </v-card>
      <v-divider class="my-5" v-if="index<news.length-1" :key="'B' + index"></v-divider>
    </template>
  </v-container>
</template>

<script>
import db from './firebaseInit'
import {mapGetters} from "vuex";

export default {
  title() {
    return `${this.$t('header.news')} - ${this.$t('header.name')}`
  },
  name: "News",
  data() {
    return {
      news: []
    }
  },
  created() {
    db.collection('news').get().then((querySnapshot) => {
      this.loading = false;
      querySnapshot.forEach((doc) => {
        const data = {
          'title': doc.data().title,
          'title_english': doc.data().title_english,
          'isHtml': doc.data().isHtml,
          'html': doc.data().html,
          'text': doc.data().text,
          'html_english': doc.data().html_english,
          'text_english': doc.data().text_english,
          'date': new Date(doc.data().date.seconds * 1000)
        }
        this.news.push(data);
      })
    });

    this.sortNews()
  },
  computed: {
    ...mapGetters([
       'language'
    ]),
    isEn() {
      return (localStorage.getItem('language') !== null && this.language === 'en');
    },
    sortNews() {
      /* eslint-disable */
      return this.news.sort((a, b) => {
        return b.date - a.date;
      });
    }
  },
}
</script>

<style scoped>

</style>
