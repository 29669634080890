<template>
  <v-container>
    <v-card>
      <v-card-text>
        <h2>{{ $t('documents.apply') }}</h2>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <tbody>
            <tr>
              <td style="width: 75%">{{ $t('documents.application_form') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/jelentkezési-lap-2024-2025.pdf" download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.application_information') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/tajekoztato-jelentkezesrol-2024.pdf"
                       download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <!--          <tr>-->
            <!--            <td>{{ $t('documents.city_application_information') }}</td>-->
            <!--            <td>-->
            <!--              <v-btn class='download-button' text outlined href="/documents/OVODAI_BEIRATKOZAS_PLAKAT_2022.pdf" download>-->
            <!--                <v-icon left>mdi-download</v-icon>-->
            <!--                {{ $t('documents.download') }}-->
            <!--              </v-btn>-->
            <!--            </td>-->
            <!--          </tr>-->
            <tr>
              <td>{{ $t('documents.kindergarten_district_list') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/ovodai-korzetjegyzek-2024_2025.pdf"
                       download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row class="justify-center">
          <v-col cols="12" md="6" sm="12">
            <img style="max-width: 100%" src="../assets/images/Hirdetmény.jpg"
                 alt="Városi tájékoztató a jelentkezésről - Simonyi úti óvoda">
          </v-col>
        </v-row>

        <h2 class="mt-10">{{ $t('documents.other_documents') }}</h2>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <tbody>
            <tr>
                <td  style="width: 75%">{{ $t('documents.founding_document') }}</td>
                <td>
                    <v-btn class='download-button' text outlined href="/documents/Alapító-okirat.pdf" download>
                        <v-icon left>mdi-download</v-icon>
                        {{ $t('documents.download') }}
                    </v-btn>
                </td>
            </tr>
            <tr>
              <td>{{ $t('documents.policy') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/Házirend  2023.03.01.pdf" download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.publication_list') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/Közzétételi-lista 2023.pdf" download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.pedagogical_program') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/Pedagógiai Program.pdf" download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.organizational_rules') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/Szervezeti és Működési Szabályzat.pdf"
                       download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.data_of_public_interest') }}</td>
              <td>
                <v-btn class='download-button' text outlined
                       href="/documents/A közérdekű adatok megismerésére irányuló kérelmek intézésének szabályzata Simonyi Úti Óvoda.pdf"
                       download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.data_management_information_1') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/Adatkezelési-Tájékoztató-1.pdf" download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.data_management_information_2') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/Adatkezelési-Tájékoztató-2.pdf" download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('documents.privacy_policy') }}</td>
              <td>
                <v-btn class='download-button' text outlined href="/documents/Adatvédelmi Szabályzat.pdf" download>
                  <v-icon left>mdi-download</v-icon>
                  {{ $t('documents.download') }}
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  title() {
    return `${this.$t('header.documents')} - ${this.$t('header.name')}`
  },
  name: "Documents"
}
</script>

<style scoped lang="scss">
.download-button {
  background-color: orange;
  color: white;
}
</style>
