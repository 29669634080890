<template>
  <div style="position: relative; padding-top: 50px;">
    <h1 class="text-center mb-10">{{ $t('otherTask.title') }}</h1>
    <v-row class="d-flex justify-center px-6 pb-10">
      <v-col xs="12" sm="6" md="4" lg="4" v-for="index in 5" :key="index">
        <app-tasks :task="other_tasks[index-1]"></app-tasks>
      </v-col>
    </v-row>
    <v-container fluid class="cloud cloud--top">
      <img class="cloud__image" src="../assets/images/cloud-top.png"  alt="Cloud - Simonyi úti óvoda">
    </v-container>
  </div>
</template>

<script>
import Task from "@/components/Task";

export default {
  name: "OtherTask",
  components: {
    appTasks: Task
  },
  data() {
    return {
      other_tasks: [
        {
          image: "user-talking.png",
          title: "otherTask.speech_therapist",
          text: "otherTask.speech_therapist_text"
        },
        {
          image: "dice.png",
          title: "otherTask.developmental_teaching_expert",
          text: "otherTask.developmental_teaching_expert_text"
        },
        {
          image: "church.png",
          title: "otherTask.religious_education",
          text: "otherTask.religious_education_text"
        },
        {
          image: "football.png",
          title: "otherTask.football",
          text: "otherTask.football_text"
        },
        {
          image: "swimmer.png",
          title: "otherTask.swimming",
          text: "otherTask.swimming_text"
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.cloud {
  position: absolute;
  left: 0;
  padding: 0;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      object-fit: fill;
    }
  }

  &--top {
    top: -95px;
    height: 110px;
  }

  &--bottom {
    bottom: -120px;
    height: 140px;
  }
}
</style>
