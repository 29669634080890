<template>
  <div style="position: relative; background-color: orange; padding-top: 20px">
    <h1 class="text-center mb-10">{{ $t('optionalTasks.title') }}</h1>
    <v-row class="d-flex justify-center px-6 pb-10">
      <v-col xs="12" sm="6" md="4" lg="4" v-for="index in 5" :key="index">
        <app-tasks :task="tasks[index-1]"></app-tasks>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Task from "@/components/Task";

export default {
  name: "OptionalTasks",
  components: {
    appTasks: Task
  },
  data() {
    return {
      tasks: [
        {
          image: "chess.svg",
          title: "optionalTasks.chess",
          text: "optionalTasks.chess_text"
        },
        {
          image: "earth.png",
          title: "optionalTasks.english",
          text: "optionalTasks.english_text"
        },
        {
          image: "dance.svg",
          title: "optionalTasks.folk_dancing",
          text: "optionalTasks.folk_dancing_text"
        },
        {
          image: "gymnastic.svg",
          title: "optionalTasks.sensory-motor_gymnastics",
          text: "optionalTasks.sensory-motor_gymnastics_text"
        },
        {
          image: "ice-skate.svg",
          title: "optionalTasks.skating",
          text: "optionalTasks.skating_text"
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>