import Vue from 'vue'
import VueRouter from "vue-router";
import VueGtag from 'vue-gtag'

import App from './App.vue'
import {routes} from "./routs";
import vuetify from './plugins/vuetify';
import store from "@/store/store";

import firebase from "firebase";
import './components/firebaseInit';

import VueGeolocation from 'vue-browser-geolocation'

import titleMixin from "@/components/mixins/titleMixin";

// import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.mixin(titleMixin)

Vue.use(VueGeolocation)

import * as VueGoogleMaps from 'vue2-google-maps'
import i18n from './i18n'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyB86lCTJB86ePOlZg0SFXejCS_R3Ek-gno'
    }
})
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0}
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!firebase.auth().currentUser) {
            next({path: '/signin', query: {redirect: to.fullPath}})
        }else {
            next();
        }
    }else {
        next();
    }
});

// Google Analytics
Vue.use(VueGtag, {
    config: {id: "G-NKFWQYJPNQ"}
}, router);

let app;
firebase.auth().onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            vuetify,
            router,
            store,
            i18n,
            render: h => h(App),
            beforeCreate() {
                this.$store.commit('initialaiseStore');
            }
        }).$mount('#app')
    }
});
