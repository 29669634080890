<template>
  <v-toolbar
      id="toolbar"
      color="white"
      min-height="100px"
      dark
      class="d-flex flex-column justify-center pl-lg-16 pr-lg-4 pl-md-0"
      style="position: relative"
  >
    <div class="d-flex align-center">
      <v-btn text class="menu-item menu-item--home" active-class="active" to="/">
        <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../assets/images/ovi_logo.png"
            transition="scale-transition"
            width="40"
        />
        <div class="black--text">{{ $t('header.name') }}</div>
      </v-btn>
    </div>

    <v-spacer></v-spacer>


    <v-btn class="hidden-md-and-up" @click="toggleMenu = !toggleMenu">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <div class="custom-menu"
         :class="[isMobile ? 'custom-menu__desktop': 'custom-menu__mobile', toggleMenu?'custom-menu__mobile--active':'']">

      <div v-if="isLoggedIn" class="align-self-center black--text">{{ currentUser }}</div>

      <v-btn text class="menu-item menu-item--kindergarten" @click="setToogleMenu" to="/buildings">
        <v-icon class="menu-item__icon">mdi-home</v-icon>
        <div class="menu-item__text">{{ $t('header.kindergarten') }}</div>
      </v-btn>

      <v-btn text class="menu-item menu-item--groups" @click="setToogleMenu" to="/groups">
        <v-icon class="menu-item__icon">mdi-account-child</v-icon>
        <div class="menu-item__text">{{ $t('header.groups') }}</div>
      </v-btn>

      <v-btn text class="menu-item menu-item--aboutus" @click="setToogleMenu" :to="{name: 'aboutus'}">
        <v-icon class="menu-item__icon">mdi-tree</v-icon>
        <div class="menu-item__text">{{ $t('header.aboutus') }}</div>
      </v-btn>

      <v-btn text class="menu-item menu-item--informations" @click="setToogleMenu" :to="{name: 'documents'}">
        <v-icon class="menu-item__icon">mdi-information</v-icon>
        <div class="menu-item__text">{{ $t('header.documents') }}</div>
      </v-btn>

      <v-btn text class="menu-item menu-item--news" @click="setToogleMenu" :to="{name: 'news'}">
        <v-icon class="menu-item__icon">mdi-newspaper</v-icon>
        <div class="menu-item__text">{{ $t('header.news') }}</div>
      </v-btn>

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs"
                 v-on="on" class="menu-item menu-item--logout">
            <div class="menu-item__icon" style="width: 36px; height: 36px;">
              <img :src="`/flags/${$i18n.locale}.svg`">
            </div>
            <div class="menu-item__text">{{ $t('header.language') }}</div>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              link
              v-for="(lang, index) in langs"
              :key="index"
              @click="setLanguage(lang)">
            <div class="language__list-item">
              <img :src="`/flags/${lang}.svg`">
            </div>
            <v-list-item-title class="ml-2">{{ $t(`header.${lang}`) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn v-if="isLoggedIn" text class="menu-item menu-item--logout" @click="logout">
        <v-icon class="menu-item__icon">mdi-logout</v-icon>
        <div class="menu-item__text">{{ $t('header.signout') }}</div>
      </v-btn>
    </div>
  </v-toolbar>
</template>

<script>
import firebase from 'firebase';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: "Header",
  data() {
    return {
      toggleMenu: false,
      langs: ['hu', 'en'],
    }
  },
  created() {
    if (firebase.auth().currentUser) {
      this.updateIsLoggedIn(true);
      this.updateCurrentUser(firebase.auth().currentUser.email);
    }
    this.$i18n.locale = this.language;
  },
  methods: {
    ...mapMutations([
      'updateIsLoggedIn',
      'updateCurrentUser',
      'updateLanguage'
    ]),
    setToogleMenu() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toggleMenu = !this.toggleMenu;
      }
    },
    logout() {
      firebase.auth().signOut().then(() => {
        this.updateIsLoggedIn(false);
        this.updateCurrentUser(false);
        this.$router.push("/")
      });
    },
    setLanguage(value) {
      this.$i18n.locale = value;
      this.updateLanguage(value);

      localStorage.setItem('language', value);
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'currentUser',
      'language'
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/main";

::v-deep .v-btn {
  height: auto !important;
}

::v-deep .v-btn__content {
  display: flex;
  flex-direction: column;
}

.custom-menu {
  display: flex;
  justify-content: end;

  &__desktop {
    flex-direction: row;
  }

  &__mobile {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    flex-direction: column;
    display: none;
    background-color: white;

    &--active {
      display: flex;
    }
  }
}

.menu-item {
  padding: 10px 16px !important;

  &__icon {
    display: flex;
    border: 1px solid;
    padding: 5px;
    margin-bottom: 7px;
    border-radius: 100%;
  }

  &--home {
    color: #eeeeee !important;
    border-color: #eeeeee !important;
  }

  &--kindergarten {
    color: $color-kindergarten !important;
    border-color: $color-kindergarten !important;
  }

  &--groups {
    color: $color-groups !important;
    border-color: $color-groups !important;
  }

  &--aboutus {
    color: $color-aboutus !important;
    border-color: $color-aboutus !important;
  }

  &--informations {
    color: $color-informations !important;
    border-color: $color-informations !important;
  }

  &--news {
    color: $color-news !important;
    border-color: $color-news !important;
  }

  &--logout {
    color: $color-logout !important;
    border-color: $color-logout !important;
  }
}

.language {
  &__list-item {
    width: 36px;
    height: 36px;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

</style>
