<template>
  <div>
    <div style="position: relative; padding-top: 20px; padding-bottom: 30px">
      <h1 class="text-center mb-5">{{$t('groups.21')}}</h1>
      <v-row class="d-flex justify-center px-6">
        <v-col xs="12" sm="6" md="4" lg="3" v-for="(card, index) in group1" :key="index">
          <app-flip-card :card="getObject(card)"></app-flip-card>
        </v-col>
      </v-row>
      <v-container fluid class="cloud cloud--bottom" style="height: 140px;">
        <img class="cloud__image" src="../assets/images/cloud-down.png" alt="Cloud - Simonyi úti óvoda">
      </v-container>
    </div>
    <div style="margin-top: 25px; padding-top: 150px; background-color: orange">
      <h1 class="text-center mb-5">{{$t('groups.31_33')}}</h1>
      <v-row class="d-flex justify-center px-6">
        <v-col xs="12" sm="6" md="4" lg="3" v-for="(card,index) in group2" :key="index">
          <app-flip-card :card="getObject(card)"></app-flip-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import FlipCard from "@/components/FlipCard";
import {groupsMixin} from "@/components/mixins/groupsMixin";

export default {
  title() {
    return `${this.$t('header.groups')} - ${this.$t('header.name')}`
  },
  name: "Groups",
  mixins: [groupsMixin],
  components: {
    appFlipCard: FlipCard
  }
}
</script>

<style scoped lang="scss">
.cloud {
  position: absolute;
  left: 0;
  padding: 0;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--top {
    top: -95px;
  }

  &--bottom {
    bottom: -160px;
  }
}
</style>