<template>
  <div>
    <GmapMap
        :center="myCoordinates31_33"
        :zoom="zoom"
        style="height:360px; margin: 32px auto;"
        ref="mapRef"
    >
      <GmapMarker
          :position="myCoordinates21"
      />
      <GmapMarker
          :position="myCoordinates31_33"
      />
    </GmapMap>
  </div>
</template>
<script>
export default {
  data() {
    return {
      map: null,
      myCoordinates21: {
        lat: 47.547170124038,
        lng: 21.627679084807976
      },
      myCoordinates31_33: {
        lat: 47.54825667862887,
        lng: 21.628260213644666
      },
      zoom: 16
    }
  }
}
</script>