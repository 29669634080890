<template>
  <v-container class="d-flex justify-center">
    <v-card min-width="500px">
      <v-card-title>Bejelentkezés</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="signin">
          <v-container>
            <v-text-field
                v-model="email"
                label="Email cím"
                required
            ></v-text-field>

            <v-text-field
                v-model="password"
                label="Jelszó"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                required
            ></v-text-field>
            <v-btn class="mr-4" type="submit">
              Bejelentkezés
            </v-btn>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase";
import {mapMutations} from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      email: '',
      password: '',
      showPassword: false
    }
  },
  methods: {
    ...mapMutations([
      'updateIsLoggedIn',
      'updateCurrentUser'
    ]),
    signin() {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(returnObj => {
                this.updateIsLoggedIn(true);
                this.updateCurrentUser(returnObj.user.email);
                this.$router.push('/createnews');
              },
              err => {
                console.error(err.message);
              });
    }
  }
}
</script>

<style scoped>

</style>