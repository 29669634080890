<template>
  <div>
    <v-row>
      <v-col
          v-for="(img, index) in getObject(folder).gallerySrc"
          :key="index"
          class="d-flex child-flex"
          cols="3"
      >
        <v-img
            :src="getImageUrl(img)"
            aspect-ratio="1"
            max-height="200px"
            class=""
            v-on:click="pictureClick(index)"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-overlay :value="overlay" opacity="0.8" z-index="1000" class="d-flex justify-center">
      <div v-click-outside="closeOverlay" class="d-flex justify-center">
        <v-btn @click="closeOverlay" id="closeOverlay">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn @click="galleryLeft()" class="included gallery-arrow gallery-arrow--left" id="gallery-arrow--left">
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <div class="image-wrapper">
          <img :src=overlaySrc alt="Gallery - Simonyi úti óvoda">
        </div>
        <v-btn @click="galleryRight()" class="included gallery-arrow gallery-arrow--right" id="gallery-arrow--right">
          <v-icon>mdi-arrow-right-circle</v-icon>
        </v-btn>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import {groupsMixin} from "@/components/mixins/groupsMixin";

export default {
  name: "Gallery",
  props: ['folder'],
  mixins: [groupsMixin],
  data() {
    return {
      overlay: false,
      overlayIndex: 0,
      overlaySrc: '',
    }
  },
  methods: {
    getImageUrl(pic) {
      return require('../assets/images/groups/' + pic)
    },
    pictureClick(index) {
      this.overlayIndex = index;
      this.overlay = true;
      this.overlaySrc = this.getImageUrl(this.getObject(this.folder).gallerySrc[this.overlayIndex]);
    },
    galleryLeft() {
      if (this.overlayIndex > 0) {
        this.overlayIndex--;
        this.overlaySrc = this.getImageUrl(this.getObject(this.folder).gallerySrc[this.overlayIndex]);
      }
    },
    galleryRight() {
      if (this.overlayIndex < this.getObject(this.folder).gallerySrc.length - 1) {
        this.overlayIndex++;
        this.overlaySrc = this.getImageUrl(this.getObject(this.folder).gallerySrc[this.overlayIndex]);
      }
    },
    closeOverlay() {
      this.overlay = false;
    }
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key === "ArrowRight" && this.overlay) {
        this.galleryRight();
      }
      if (e.key === "ArrowLeft" && this.overlay) {
        this.galleryLeft();
      }
      if (e.key === "Escape" && this.overlay) {
        this.closeOverlay();
      }
    });
  }
}
</script>

<style scoped lang="scss">

::v-deep .v-overlay__content {
  display: flex !important;
  justify-content: center !important;
}

.image-wrapper {
  max-width: 70%;

  & img {
    width: 100%;
    height: 100%;
  }
}

#closeOverlay {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1001;
}

.gallery-arrow {
  position: fixed;
  top: 50%;
  z-index: 1001;

  &--left {
    left: 30px;
  }

  &--right {
    right: 30px;
  }
}
</style>